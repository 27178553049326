import "./index.scss";
export function sidebarInit() {
    var burger = document.querySelector(".burger");
    var sidebar = document.querySelector(".sidebar");
    var body = document.querySelector("body");
    var closeButton = document.querySelector(".sidebar__close");
    if (burger) {
        burger.addEventListener("click", function (e) {
            e.stopPropagation();
            if (!burger.classList.contains("active")) {
                open();
            }
        });
    }
    if (closeButton) {
        closeButton.addEventListener("click", function () {
            close();
        });
    }
    function outsideClickHandler(e) {
        var isClickInsideMenu = sidebar && e.composedPath().includes(sidebar);
        if (!isClickInsideMenu) {
            close();
        }
    }
    function open() {
        document.addEventListener("click", outsideClickHandler);
        burger && burger.classList.add("active");
        sidebar && sidebar.classList.add("active");
        body && body.classList.add("_lock");
    }
    function close() {
        document.removeEventListener("click", outsideClickHandler);
        document.dispatchEvent(new Event("sidebar:close"));
        burger && burger.classList.remove("active");
        sidebar && sidebar.classList.remove("active");
        body && body.classList.remove("_lock");
        document.dispatchEvent(new Event("sidebar:closed"));
    }
}
