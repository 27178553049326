import "./index.scss";
var navItems = [];
var NavItem = /** @class */ (function () {
    function NavItem(el) {
        this.el = el;
        this.sublist = null;
        this.sublist = this.el.querySelector(".nav__sublist");
        this.setupListeners();
    }
    NavItem.prototype.setupListeners = function () {
        var _this = this;
        this.el.addEventListener("click", function (e) {
            if (_this.sublist && e.composedPath().includes(_this.sublist)) {
                return;
            }
            e.stopPropagation();
            if (!_this.el.classList.contains("active")) {
                NavItem.closeAllSubmenus();
                _this.openSubmenu();
            }
            else {
                _this.closeSubmenu();
            }
        });
    };
    NavItem.prototype.openSubmenu = function () {
        this.el.classList.add("active");
        document.addEventListener("click", this.navMenuOutsideClickHandler);
    };
    NavItem.prototype.navMenuOutsideClickHandler = function (e) {
        var isClickInsideMenu = e.composedPath().includes(this.el);
        if (!isClickInsideMenu) {
            NavItem.closeAllSubmenus();
        }
    };
    NavItem.prototype.closeSubmenu = function () {
        this.el.classList.remove("active");
        document.removeEventListener("click", this.navMenuOutsideClickHandler);
    };
    NavItem.closeAllSubmenus = function () {
        if (navItems) {
            navItems.forEach(function (item) {
                item.closeSubmenu();
            });
        }
    };
    return NavItem;
}());
export function headerNavInit() {
    var items = document.querySelectorAll(".nav__item");
    items === null || items === void 0 ? void 0 : items.forEach(function (item) {
        navItems.push(new NavItem(item));
    });
}
