import "swiper/swiper-bundle.min.css";
import Swiper, { Navigation, Pagination, Lazy } from "swiper";
import "./reviews.scss";
export function blockReviewsInit() {
    if (document.querySelector(".reviews__items")) {
        var swiperReviews = new Swiper(".reviews__items", {
            modules: [Navigation, Pagination, Lazy],
            speed: 500,
            slidesPerView: 1,
            loop: false,
            lazy: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: ".reviews .slider-button_next",
                prevEl: ".reviews .slider-button_prev",
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 32,
                },
                950: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                },
                1150: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                },
            },
            on: {
                resize: function enableOnlyMobile(swiperReviews) {
                    if (951 < window.innerWidth) {
                        swiperReviews.disable();
                        swiperReviews.el.classList.add("-non-slider");
                    }
                    else {
                        swiperReviews.enable();
                        swiperReviews.el.classList.remove("-non-slider");
                    }
                },
            },
        });
        var reviewsItems_1 = document.querySelector(".reviews__items");
        var buttonVisibleAll_1 = document.querySelector(".reviews__button");
        swiperReviews.disable();
        swiperReviews.el.classList.add("-non-slider");
        if (buttonVisibleAll_1 && reviewsItems_1) {
            buttonVisibleAll_1.addEventListener("click", function (e) {
                e.preventDefault();
                buttonVisibleAll_1.classList.toggle("active");
                reviewsItems_1.classList.toggle("visible-all");
            });
        }
    }
}
