import "./news.scss";
var isNewsBlockInitialized = false;
export function blockNewsInit() {
    if (!isNewsBlockInitialized) {
        var buttonNewsVisibleAll_1 = document.querySelector(".news__button");
        var newsItems_1 = document.querySelector(".news__items-box");
        if (buttonNewsVisibleAll_1 && newsItems_1) {
            buttonNewsVisibleAll_1.addEventListener("click", function (e) {
                e.preventDefault();
                buttonNewsVisibleAll_1.classList.toggle("active");
                newsItems_1.classList.toggle("visible-all");
            });
        }
        isNewsBlockInitialized = true;
    }
}
