// Styles
import "@/shared/ui/config/scss/__globals.scss";
// import "@/widgets/layouts/header/index.scss";
// import "@/widgets/nav/headerNav/index.scss";
// import "@/widgets/layouts/sidebar/index.scss";
// import "@/widgets/layouts/main/index.scss";
// import "@/widgets/layouts/footer/index.scss";
// import "@/widgets/nav/sidebarNav/index.scss";
// import "@/features/sidebarForm/index.scss";
// import "@/widgets/nav/footerNav/index.scss";
// import "@/shared/ui/popup/_index.scss";
import { getPopup } from "@/shared/ui/popup";
import { sidebarFormInit } from "@/features/sidebarForm";
import "@/widgets/layouts/header";
import "@/widgets/layouts/main";
import "@/widgets/layouts/footer";
import "@/widgets/nav/footerNav";
import { headerNavInit } from "@/widgets/nav/headerNav";
import { sidebarInit } from "@/widgets/layouts/sidebar";
import { sidebarNavInit } from "@/widgets/nav/sidebarNav";
import "./blocks/promo";
import "./blocks/excursion";
import "./blocks/software";
import "./blocks/modernization";
import { blockPortfolioInit } from "./blocks/portfolio";
import { blockNewsInit } from "./blocks/news";
import { blockAccessoriesInit } from "./blocks/accessories";
import { blockPopularsInit } from "./blocks/populars";
import { blockClientsInit } from "./blocks/clients";
import { blockReviewsInit } from "./blocks/reviews";
import "./blocks/callback";
import "./index.scss";
export function homePageInit() {
    headerNavInit();
    sidebarInit();
    sidebarNavInit();
    sidebarFormInit();
    var popup = getPopup();
    blockAccessoriesInit();
    blockPortfolioInit();
    blockPopularsInit();
    blockClientsInit();
    blockNewsInit();
    blockReviewsInit();
}
