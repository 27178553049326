import "./portfolio.scss";
var isPortfolioBlockInitialized = false;
export function blockPortfolioInit() {
    if (!isPortfolioBlockInitialized) {
        var buttonPortfolioVisibleAll_1 = document.querySelector(".portfolio__item-show-more");
        var portfolioItems_1 = document.querySelector(".portfolio__items");
        if (buttonPortfolioVisibleAll_1 && portfolioItems_1) {
            buttonPortfolioVisibleAll_1.addEventListener("click", function (e) {
                e.preventDefault();
                buttonPortfolioVisibleAll_1.classList.toggle("active");
                portfolioItems_1.classList.toggle("visible-all");
            });
        }
        isPortfolioBlockInitialized = true;
    }
}
