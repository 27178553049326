import "./index.scss";
export function sidebarNavInit() {
    var sidebarNavItem = document.querySelectorAll(".sidebarNav__item");
    sidebarNavItem.forEach(function (item) {
        item.addEventListener("click", function () {
            if (!item.classList.contains("active")) {
                sidebarNavItem.forEach(function (item) {
                    item.classList.remove("active");
                });
                item.classList.add("active");
            }
            else {
                item.classList.remove("active");
            }
        });
    });
    document.addEventListener("sidebar:close", function () {
        sidebarNavItem.forEach(function (item) {
            item.classList.remove("active");
        });
    });
}
