import { formSearchInit } from "@/features/headerSearch";
import "./index.scss";
formSearchInit();
function headerScrollInit() {
    var header = document.querySelector("header.header");
    function headerScrollHandler() {
        if (window.scrollY >= 50) {
            header === null || header === void 0 ? void 0 : header.classList.add("header_small");
        }
        else {
            header === null || header === void 0 ? void 0 : header.classList.remove("header_small");
        }
    }
    window.addEventListener("scroll", headerScrollHandler);
}
headerScrollInit();
