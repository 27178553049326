import "./_index.scss";
var PopupSingleton = /** @class */ (function () {
    function PopupSingleton(selectorsByIds) {
        if (selectorsByIds === void 0) { selectorsByIds = {
            container: "popup",
            content: "popup-content",
            closeBtn: "popup-close-btn",
        }; }
        this.selectorsByIds = selectorsByIds;
        this.containerElem = null;
        this.contentElem = null;
        this.closeBtnElem = null;
        this.lastRenderedTplId = "";
        this.setupElements();
        this.setupListeners();
    }
    PopupSingleton.getInstance = function (selectorsByIds) {
        if (!PopupSingleton.instance) {
            PopupSingleton.instance = new PopupSingleton(selectorsByIds);
        }
        return PopupSingleton.instance;
    };
    PopupSingleton.prototype.setupElements = function () {
        var _a, _b, _c;
        this.containerElem = document.getElementById((_a = this.selectorsByIds) === null || _a === void 0 ? void 0 : _a.container);
        if (!this.containerElem)
            throw new Error("Popup: Container element not found");
        this.contentElem = document.getElementById((_b = this.selectorsByIds) === null || _b === void 0 ? void 0 : _b.content);
        if (!this.contentElem)
            throw new Error("Popup: Content Element Not Found");
        this.closeBtnElem = document.getElementById((_c = this.selectorsByIds) === null || _c === void 0 ? void 0 : _c.closeBtn);
        if (!this.closeBtnElem)
            throw new Error("Popup: Close Button Not Found");
    };
    PopupSingleton.prototype.setupListeners = function () {
        var _this = this;
        var _a, _b;
        (_a = this.closeBtnElem) === null || _a === void 0 ? void 0 : _a.addEventListener("click", this.onClickCloseBtn);
        (_b = this.containerElem) === null || _b === void 0 ? void 0 : _b.addEventListener("click", function (e) {
            return _this.onClickContainer(e);
        });
        var triggerBtns = document.querySelectorAll("[data-popup-trigger]");
        triggerBtns.forEach(function (btn) {
            btn === null || btn === void 0 ? void 0 : btn.addEventListener("click", function (e) {
                var _a, _b;
                e.preventDefault();
                var tplId = btn.dataset.popupContentContainer;
                var renderContent;
                var rerender = tplId !== _this.lastRenderedTplId;
                if (tplId) {
                    renderContent =
                        (_b = (_a = document.getElementById(tplId)) === null || _a === void 0 ? void 0 : _a.innerHTML) !== null && _b !== void 0 ? _b : undefined;
                }
                if (tplId && renderContent) {
                    _this.lastRenderedTplId = tplId;
                }
                _this.open(renderContent, rerender);
            });
        });
    };
    PopupSingleton.prototype.onClickCloseBtn = function (e) {
        PopupSingleton.getInstance().close();
    };
    PopupSingleton.prototype.onClickContainer = function (e) {
        if (e.target === this.containerElem) {
            PopupSingleton.getInstance().close();
        }
    };
    PopupSingleton.prototype.close = function () {
        var _a;
        document.dispatchEvent(new Event("popup:close"));
        (_a = this.containerElem) === null || _a === void 0 ? void 0 : _a.classList.remove("open");
        // document.body.classList.remove("_lock");
        document.dispatchEvent(new Event("popup:closed"));
    };
    PopupSingleton.prototype.open = function (renderContent, rerender) {
        var _a;
        if (renderContent === void 0) { renderContent = "Hello World!"; }
        if (rerender === void 0) { rerender = true; }
        document.dispatchEvent(new Event("popup:open"));
        if (this.contentElem && rerender) {
            this.contentElem.innerHTML = renderContent;
        }
        (_a = this.containerElem) === null || _a === void 0 ? void 0 : _a.classList.add("open");
        // document.body.classList.add("_lock");
        document.dispatchEvent(new Event("popup:opened"));
    };
    PopupSingleton.prototype.getContentElement = function () {
        return this.contentElem;
    };
    return PopupSingleton;
}());
export function getPopup() {
    return PopupSingleton.getInstance({
        container: "popup",
        content: "popup-content",
        closeBtn: "popup-close-btn",
    });
}
